class SwymPopup extends HTMLElement {
    constructor() {
      super();

      const closeButtons = this.querySelectorAll('.close-popup');
      this.imageContainer = this.querySelector('.main-image')

      closeButtons.forEach(button => {
        button.addEventListener('click', e => {
            console.log('click')
            this.toggle();
        })
      })
    }

    toggle(){
        this.classList.toggle('hidden')
    }

    swapImage(image) {
        this.imageContainer.setAttribute('src', image)
    }
  }
  
  customElements.define('swym-popup', SwymPopup);
  